import React, { FC, useEffect, useRef, useState } from 'react';
import { BlockWrapper } from '../../common/block-wrapper';
import { Header } from './header';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { TLocaleKey } from '../../../locale';

const BlockWrapperStyled = styled(BlockWrapper)`
  @media (min-width: 768px) {
    grid-row-start: 2;
    grid-column-start: 2;
  }

  @media (max-width: 1075px) {
    padding-bottom: 30px;
  }

  @media (min-width: 1215px) {
    grid-row-start: unset;
    grid-column-start: unset;
  }
`;

const Wrapper = styled('div')`
  background-color: ${({ theme }) => theme.COLORS.WHITE._200};
  padding: 17px 30px 30px;
  border-radius: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1075px) {
    padding: 17px 0 30px;
  }
`;

const Title = styled('h4')`
  color: ${({ theme }) => theme.COLORS.SECONDARY};
  font-family: 'Inter', sans-serif, 'Open Sans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  padding: 0;
  margin-bottom: 15px;
  margin-top: 0;
  margin-left: 0;
`;

const Text = styled('p')`
  color: ${({ theme }) => theme.COLORS.SECONDARY};
  font-family: 'Inter', sans-serif, 'Open Sans', sans-serif;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 0;
  padding: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 24px */

  &:not(:last-child) {
    margin-bottom: 50px;
  }
`;

const TextWrapper = styled('div')`
  height: auto;
  margin-bottom: 70px;

  @media (max-width: 767px) {
    padding-inline: 20px;
  }
`;

const ButtonsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: auto;

  @media (max-width: 767px) {
    padding-inline: 20px;
  }

  @media (max-width: 1075px) {
    height: 74px;
    align-items: center;
    position: fixed;
    bottom: 0;
    flex-direction: row;
    background-color: ${({ theme }) => theme.COLORS.WHITE._200};
  }
`;

const Button = styled('div')<{ primary: 'true' | 'false' }>`
  font-family: 'Inter', sans-serif, 'Open Sans', sans-serif;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  color: ${({ theme }) => theme.COLORS.SECONDARY};
  background-color: ${({ theme, primary }) =>
    primary === 'true' ? theme.COLORS.PRIMARY : theme.COLORS.GRAY._200};
  cursor: pointer;

  @media (max-width: 1075px) {
    height: 50px;
    font-size: 14px;
  }
`;

export const HowItWorks: FC = () => {
  const { t } = useTranslation([TLocaleKey.DEMO]);
  const blockRef = useRef<HTMLDivElement>(null);
  const [blockWidth, setBlockWidth] = useState(0);

  useEffect(() => {
    const listener = () => {
      setBlockWidth(0);

      setTimeout(() => {
        if (blockRef.current) {
          setBlockWidth(blockRef.current.clientWidth);
        }
      }, 0);
    };

    listener();

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  return (
    <BlockWrapperStyled>
      <Header />
      <Wrapper>
        <TextWrapper ref={blockRef}>
          <Title>{t('howItWorks.analyticsBlock.title')}</Title>
          <Text>{t('howItWorks.analyticsBlock.text')}</Text>
          <Title>{t('howItWorks.confBlock.title')}</Title>
          <Text>{t('howItWorks.confBlock.text')}</Text>
          <Title>{t('howItWorks.postAnalyticsBlock.title')}</Title>
          <Text>{t('howItWorks.postAnalyticsBlock.text')}</Text>
        </TextWrapper>
        <ButtonsWrapper
          style={{
            width: blockWidth,
          }}
        >
          <Button primary={'false'}>{t('buttons.toCite')}</Button>
          <Button primary={'true'}>{t('buttons.login')}</Button>
        </ButtonsWrapper>
      </Wrapper>
    </BlockWrapperStyled>
  );
};
