import React, { FC, useMemo } from 'react';
import { BlockWrapper } from '../../common/block-wrapper';
import { Header } from './header';
import styled from '@emotion/styled';
import { StatItem } from './stat-item';
import { useIndexesContext } from '../../../context/indexes-context';
import {
  ATTENTION_STATUS_TEXT,
  attentionToStatus,
} from '../../../utils/indexes';
import { useTranslation } from 'react-i18next';
import { TLocaleKey } from '../../../locale';

const ContentWrapper = styled('div')`
  background-color: ${({ theme }) => theme.COLORS.WHITE._200};
  border-radius: 20px;
  padding: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1075px) {
    padding: 0;
  }

  @media (max-width: 767px) {
    padding-inline: 20px;
  }
`;

export const StatsBlock: FC = () => {
  const { t } = useTranslation([TLocaleKey.DEMO]);
  const { indexes, average } = useIndexesContext();

  const currentAttentionStatus = useMemo(() => {
    return attentionToStatus(average.attention);
  }, [average.attention]);

  return (
    <BlockWrapper>
      <Header />
      <ContentWrapper>
        <StatItem
          title={t('statsBlock.statItems.trafficLight')}
          tooltipText={t('statsBlock.statItems.trafficLightTooltip')}
          status={currentAttentionStatus}
          text={ATTENTION_STATUS_TEXT(t)[currentAttentionStatus]}
        />
        <StatItem
          title={t('statsBlock.statItems.attention')}
          tooltipText={t('statsBlock.statItems.attentionTooltip')}
          chartData={indexes.filter((el) => el.key === 'attention')}
          color={'blue'}
          percent={average.attention ?? 0}
        />
        <StatItem
          title={t('statsBlock.statItems.involvement')}
          tooltipText={t('statsBlock.statItems.involvementTooltip')}
          chartData={indexes.filter((el) => el.key === 'involvement')}
          color={'violet'}
          percent={average.involvement ?? 0}
        />
        <StatItem
          title={t('statsBlock.statItems.emotions')}
          tooltipText={t('statsBlock.statItems.emotionsTooltip')}
          chartData={indexes.filter((el) => el.key === 'happiness')}
          color={'orange'}
          percent={average.happiness ?? 0}
        />
        <StatItem
          title={t('statsBlock.statItems.fatigue')}
          tooltipText={t('statsBlock.statItems.fatigueTooltip')}
          chartData={indexes.filter((el) => el.key === 'tiredness')}
          color={'gray'}
          percent={average.tiredness ?? 0}
        />
        <StatItem
          title={t('statsBlock.statItems.astonishment')}
          tooltipText={t('statsBlock.statItems.astonishmentTooltip')}
          chartData={indexes.filter((el) => el.key === 'amazement')}
          color={'red'}
          percent={average.amazement ?? 0}
        />
      </ContentWrapper>
    </BlockWrapper>
  );
};
