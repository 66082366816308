import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
// import { Status } from './status';
import { CameraButton } from './camera-button';
import { CameraVideo } from './camera-video';
import Webcam from 'react-webcam';
import { App } from 'antd';
import { useIndexes } from '../../hooks/useIndexes';
import { FaceDetectorCallbacks, IIndex } from '../../types/index-core';
import { useIndexesContext } from '../../context/indexes-context';
import { indexesToChartData } from '../../utils/functions/charts';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { TLocaleKey } from '../../locale';
import { useTirednessDetector } from '../../hooks/useTirednessDetector';
import { FaceExpressions } from 'face-api.js';

const Wrapper = styled('div')`
    width: 100%;
    height: 100%;
`;

const LoadingWrapper = styled('div')`
    position: absolute;
    background-color: ${({ theme }) => theme.COLORS.WHITE._200};
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 50px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

export const Camera: FC = () => {
  const { t } = useTranslation([TLocaleKey.DEMO]);

  const { message } = App.useApp();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const { handleAddIndex } = useIndexesContext();
  const { detectFaces } = useTirednessDetector();

  const webcamRef = useRef<Webcam | null>(null);

  const [cameraState, setCameraState] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleButtonClick = useCallback(async () => {
    if (cameraState) {
      webcamRef.current?.video?.load();
      setCameraState(false);
    } else {
      if (
        'mediaDevices' in navigator &&
        'getUserMedia' in navigator.mediaDevices
      ) {
        try {
          await navigator.mediaDevices.getUserMedia({ video: true });

          if (webcamRef.current && 'stream' in webcamRef.current) {
            void webcamRef.current?.video?.play();
            setCameraState(true);
          }
        } catch (e) {
          void message.warning(t('mainBlock.camera.needAccess'));
        }
      } else {
        void message.error(t('mainBlock.camera.cannotUse'));
      }
    }
  }, [cameraState, t]);

  let blinks = 0;
  let blinkTime = 0;

  const handleIndex: FaceDetectorCallbacks['onIndex'] =
    useCallback((index: IIndex) => {
      blinks = index.statistic.blinks;
      blinkTime = index.statistic.blinkTime;
    }, []);

  let currentTime = -1;
  let lastTime = -1;
  let lastBlinksTrackTime = 0;
  let lastBlinks = 0;
  const minimalTiredness = 5;
  let tiredness = minimalTiredness;
  let avgTirednessInstant = 0;

  const handleSecondIndex: FaceDetectorCallbacks['onSecondIndex'] = useCallback(
    async (index) => {
      if (!index) return;

      currentTime = new Date().getTime();
      const deltaTime = currentTime - lastTime;
      lastTime = currentTime;

      if (lastBlinksTrackTime === 0) {
        lastBlinksTrackTime = currentTime;
        lastBlinks = blinks;
      }

      if (currentTime - lastBlinksTrackTime > 30000) {
        lastBlinksTrackTime = currentTime;
        const deltaBlinks =  blinks - lastBlinks;
        avgTirednessInstant = 0;
        switch (deltaBlinks) {
          case 6:
            avgTirednessInstant = 10;
            break;
          case 7:
          case 8:
          case 9:
            avgTirednessInstant = 20;
            break;
          case 10:
          case 11:
          case 12:
            avgTirednessInstant = 30;
            break;
          case 13:
          case 14:
          case 15:
            avgTirednessInstant = 40;
            break;
        }
        lastBlinks = blinks;
      }

      if (webcamRef.current?.video) {
        // tslint:disable-next-line:no-unused-expression
        const { tirednessFactor, expressions } = await detectFaces(webcamRef.current?.video);

        let maxSpeed = 4.0;
        let forceDecrease = 0;
        if (tirednessFactor < 30.0) {
          maxSpeed = 8.0;
        } else if (tirednessFactor < 60.0) {
          maxSpeed = 2.0;
        } else if (tirednessFactor > 80) {
          maxSpeed = 4.0;
          forceDecrease = 1;
        }

        const increaseFactors =
          (
            ((((expressions as FaceExpressions).sad || 0) > 0.05 ? 1: 0) * 2.0) +
            ((((expressions as FaceExpressions).neutral || 0) > 0.6 ? 1: 0) * 10.0) +
            Math.pow(Math.max(Math.min(tirednessFactor, 150, 0)) / 150, 1)
          ) / 10.0;

        const decreaseFactors = (((expressions as FaceExpressions).neutral || 0) + ((expressions as FaceExpressions).happy || 0) * 2.0 + forceDecrease) / 3.0;
        let delta = (increaseFactors - decreaseFactors) * maxSpeed;
        delta = Math.max(Math.min(delta, maxSpeed), -maxSpeed);

        tiredness += delta;

        if (tiredness <= minimalTiredness) {
          tiredness = minimalTiredness;
        }
        if (tiredness >= 100) {
          tiredness = 100;
        }

        index.index.tiredness = Math.round(tiredness * 0.4 + avgTirednessInstant * 0.6);
      }

      handleAddIndex(indexesToChartData(index.index));
    },
    [],
  );

  const [initDetector, destroyDetector, isFaceDetectorInit] = useIndexes({
    videoElementRef: webcamRef.current?.video,
    callbacks: {
      onIndex: handleIndex,
      onSecondIndex: handleSecondIndex,
      onCanvasRendered: () => {
        setLoading(false);
      },
    },
    canvas: canvasRef.current,
  });

  useEffect(() => {
    if (cameraState && !isFaceDetectorInit) {
      setLoading(true);
      setTimeout(() => {
        void initDetector();
      }, 0);
    } else if (isFaceDetectorInit && !cameraState) {
      setLoading(false);
      void destroyDetector();
    }
  }, [cameraState, isFaceDetectorInit]);

  return (
    <Wrapper>
      <canvas
        ref={canvasRef}
        style={{
          width: '0',
          height: '0',
          objectFit: 'cover',
        }}
      />
      {loading && (
        <LoadingWrapper>
          <LoadingOutlined />
        </LoadingWrapper>
      )}
      {/*<Status />*/}
      <CameraButton onClick={handleButtonClick} state={cameraState} />
      <CameraVideo active={cameraState} ref={webcamRef} />
    </Wrapper>
  );
};
