export type MainTheme = {
  PRIMARY: string;
  SECONDARY: string;
  SUCCESS: string;
  WARNING: string;
  ERROR: string;
  VIOLET: string;
  WHITE: {
    _100: string;
    _200: string;
    _300: string;
  };
  GRAY: {
    _100: string;
    _200: string;
  };
};

export const lightTheme: MainTheme = {
  PRIMARY: '#1C72F3',
  SECONDARY: '#031B40',
  SUCCESS: '#5CF66C',
  WARNING: '#FFCC17',
  ERROR: '#FA5555',
  VIOLET: '#8900FF',
  WHITE: {
    _100: '#FFFFFF',
    _200: '#EEF4FE',
    _300: '#BDD6FC',
  },
  GRAY: {
    _100: '#53555C',
    _200: '#53555C',
  },
};

export const darkTheme: MainTheme = {
  PRIMARY: '#1C72F3',
  SECONDARY: '#FFFFFF',
  SUCCESS: '#5CF66C',
  WARNING: '#FFCC17',
  ERROR: '#FA5555',
  VIOLET: '#8900FF',
  WHITE: {
    _100: '#18191D',
    _200: '#202124',
    _300: '#292B30',
  },
  GRAY: {
    _100: '#53555C',
    _200: '#616266',
  },
};
