import React, { FC } from 'react';
import styled from '@emotion/styled';

import logo from '../../../assets/logo.svg';

const Wrapper = styled('header')`
  height: 44px;
  display: flex;
  align-items: center;
  margin-top: 40px;

  @media (max-width: 1075px) {
    position: fixed;
    top: 20px;
    left: 20px;
    height: 30px;
    margin-top: 0;
    z-index: 2;
  }

  @media (max-width: 767px) {
    position: absolute;
  }

  img {
    height: 100%;
    margin-right: 30px;
  }

  span {
    font-family: 'Inter', sans-serif, 'Open Sans', sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    color: ${({ theme }) => theme.COLORS.SECONDARY};
    opacity: 0.8;
  }
`;

export const Header: FC = () => {
  return (
    <Wrapper>
      <img src={logo} alt="" />
    </Wrapper>
  );
};
