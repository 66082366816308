import React, { FC } from 'react';
import styled from '@emotion/styled';
import { MainBlock } from '../components/home/main-block';
import { StatsBlock } from '../components/home/stats-block';
import { HowItWorks } from '../components/home/how-it-works';

const Wrapper = styled('div')`
  display: grid;
  column-gap: 30px;
  overflow-y: auto;
  min-height: 100vh;
  height: 100vh;
  max-width: 1800px;
  margin: 0 auto;
  padding-inline: 60px;
  padding-bottom: 90px;
  grid-template-rows: auto auto;
  background-color: ${({ theme }) => theme.COLORS.WHITE._100};

  @media (min-width: 768px) {
    grid-template-columns: 54fr 46fr;
  }

  @media (max-width: 1075px) {
    padding-inline: unset;
    padding-left: 0;
    padding-right: 20px;
    padding-bottom: 0;
    column-gap: 20px;
    background-color: ${({ theme }) => theme.COLORS.WHITE._200};
  }

  @media (min-width: 1215px) {
    grid-template-rows: auto;
    grid-template-columns: 27fr 10fr 10fr;
  }

  @media (max-width: 767px) {
    grid-template-rows: unset;
    grid-template-columns: 1fr;
    padding-right: 0;
  }
`;

export const Home: FC = () => {
  return (
    <Wrapper>
      <MainBlock />
      <StatsBlock />
      <HowItWorks />
    </Wrapper>
  );
};
