import React, { FC } from 'react';
import styled from '@emotion/styled';
import { TStatus } from '../../../../types/common';
import { css } from '@emotion/css';
import { lightTheme } from '../../../../utils/theme';

const statusToColor = (status: TStatus) => {
  switch (status) {
    case 'success':
      return css`
        background-color: ${lightTheme.SUCCESS};
        filter: drop-shadow(
            0px 2.200000047683716px 3.748148202896118px rgba(92, 246, 108, 0.08)
          )
          drop-shadow(
            0px 10.399999618530273px 14.651851654052734px
              rgba(92, 246, 108, 0.12)
          )
          drop-shadow(0px 27px 46px rgba(92, 246, 108, 0.2));
      `;
    case 'warning':
      return css`
        background-color: ${lightTheme.WARNING};
        filter: drop-shadow(
            0px 2.200000047683716px 3.748148202896118px rgba(246, 185, 92, 0.08)
          )
          drop-shadow(
            0px 10.399999618530273px 14.651851654052734px
              rgba(246, 185, 92, 0.12)
          )
          drop-shadow(0px 27px 46px rgba(246, 185, 92, 0.2));
      `;
    case 'error':
      return css`
        background-color: ${lightTheme.ERROR};
        filter: drop-shadow(
            0px 2.200000047683716px 3.748148202896118px rgba(250, 85, 85, 0.08)
          )
          drop-shadow(
            0px 10.399999618530273px 14.651851654052734px
              rgba(250, 85, 85, 0.12)
          )
          drop-shadow(0px 27px 46px rgba(250, 85, 85, 0.2));
      `;
  }
};

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-left: 25px;
`;

const StatusIcon = styled('div')`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
`;

const Text = styled('span')`
  color: ${({ theme }) => theme.COLORS.SECONDARY};
  font-family: 'Inter', sans-serif, 'Open Sans', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  opacity: 0.9;
`;

type TProps = {
  status: TStatus;
  text: string;
};

export const StatusContent: FC<TProps> = ({ status, text }) => {
  return (
    <Wrapper>
      <StatusIcon className={statusToColor(status)} />
      <Text>{text}</Text>
    </Wrapper>
  );
};
