import React, { FC } from 'react';
import styled from '@emotion/styled';

const Wrapper = styled('div')`
  height: 44px;
  display: flex;
  align-items: center;
  margin-top: 40px;

  @media (max-width: 1075px) {
    margin-top: 20px;
  }
`;

export const Header: FC = () => {
  return (
    <Wrapper>
      {/*<SectionTitle>{t('statsBlock.title')}</SectionTitle>*/}
    </Wrapper>
  );
};
