import { TChartColor } from '../../types/common';
import { lightTheme } from '../theme';

export const chartToColor = (status: TChartColor) => {
  switch (status) {
    case 'red':
      return lightTheme.ERROR;
    case 'orange':
      return lightTheme.WARNING;
    case 'violet':
      return lightTheme.VIOLET;
    case 'gray':
      return lightTheme.GRAY._100;
    case 'blue':
      return lightTheme.PRIMARY;
  }
};
