import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home } from '../../pages';

export const RootRoutes: FC = () => {
  return (
    <Routes>
      <Route path={'/'} element={<Home />} />
    </Routes>
  );
};
