import React, { FC, useRef } from 'react';
import styled from '@emotion/styled';
import { Question } from '../../../common/icons/question';
import { Tooltip } from 'antd';
import { TChartColor, TStatus } from '../../../../types/common';
import { StatusContent } from './status-content';
import { ChartContent } from './chart-content';
import { TChartDataItem } from '../../../../utils/functions/charts';

const Wrapper = styled('div')`
  background-color: ${({ theme }) => theme.COLORS.WHITE._300};
  border-radius: 20px;
  overflow: hidden;
  flex: 1;

  @media (max-width: 1075px) {
    flex: unset;
    height: 130px;
  }

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
`;

const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 14px 20px 25px;
`;

const HeaderSide = styled('div')`
  display: flex;
  align-items: center;
  gap: 20px;

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: ${({ theme }) => theme.COLORS.GRAY._200};
    }
  }
`;

const IconContainer = styled('div')`
  padding-right: 11px;

  .ico-inner {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;

const Title = styled('h4')`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.COLORS.SECONDARY};
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

const ContentWrapper = styled('div')`
  height: calc(100% - 62px);
`;

type TStatusProps = {
  status: TStatus;
  text: string;
};

type TChartProps = {
  chartData: TChartDataItem[];
  color: TChartColor;
};

type TProps = {
  percent?: number;
  tooltipText?: string;
  title: string;
};

type TOwnProps = TProps & (TStatusProps | TChartProps);

export const StatItem: FC<TOwnProps> = ({
  title,
  percent,
  tooltipText,
  ...props
}) => {
  const sideRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper>
      <Header>
        <Title>{title}</Title>
        <HeaderSide ref={sideRef}>
          {percent !== undefined && <Title>{percent}%</Title>}
          {tooltipText && (
            <Tooltip title={tooltipText} placement="bottomRight">
              <IconContainer>
                <div className="ico-inner">
                  <Question />
                </div>
              </IconContainer>
            </Tooltip>
          )}
        </HeaderSide>
      </Header>
      <ContentWrapper>
        {'status' in props ? (
          <StatusContent status={props.status} text={props.text} />
        ) : (
          <ChartContent
            keyS={title}
            chartData={props.chartData}
            color={props.color}
          />
        )}
      </ContentWrapper>
    </Wrapper>
  );
};
