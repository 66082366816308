// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  background-color: #18191D;
}

.ant-tooltip {
  --antd-arrow-background-color: #202124;
}

.ant-tooltip .ant-tooltip-inner {
  background-color: #202124;
}`, "",{"version":3,"sources":["webpack://./src/utils/theme/style.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,yBAAA;AACF;;AAEA;EACE,sCAAA;AACF;;AAEA;EACE,yBAAA;AACF","sourcesContent":["#root {\n  width: 100vw;\n  height: 100vh;\n  max-width: 100vw;\n  overflow: hidden;\n  background-color: #18191D;\n}\n\n.ant-tooltip {\n  --antd-arrow-background-color: #202124;\n}\n\n.ant-tooltip .ant-tooltip-inner {\n  background-color: #202124;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
