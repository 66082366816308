import React, { FC } from 'react';

export const Question: FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7188 9.25C17.7187 14.0664 13.7812 17.9688 9 17.9688C4.18359 17.9688 0.28125 14.0664 0.28125 9.25C0.28125 4.46875 4.18359 0.53125 9 0.53125C13.7812 0.53125 17.7188 4.46875 17.7188 9.25ZM9.21094 3.41406C7.3125 3.41406 6.08203 4.22266 5.13281 5.66406C4.99219 5.875 5.02734 6.12109 5.20312 6.26172L6.43359 7.17578C6.60937 7.31641 6.89062 7.28125 7.03125 7.10547C7.66406 6.29688 8.08594 5.83984 9.03516 5.83984C9.73828 5.83984 10.6523 6.29688 10.6523 7C10.6523 7.52734 10.1953 7.80859 9.49219 8.19531C8.68359 8.65234 7.59375 9.21484 7.59375 10.6563V10.7969C7.59375 11.043 7.76953 11.2188 8.01562 11.2188H9.98437C10.1953 11.2188 10.4062 11.043 10.4062 10.7969V10.7617C10.4062 9.77734 13.3242 9.74219 13.3242 7C13.3242 4.96094 11.2148 3.41406 9.21094 3.41406ZM9 12.1328C8.08594 12.1328 7.38281 12.8711 7.38281 13.75C7.38281 14.6641 8.08594 15.3672 9 15.3672C9.87891 15.3672 10.6172 14.6641 10.6172 13.75C10.6172 12.8711 9.87891 12.1328 9 12.1328Z"
        fill="#545559"
      />
    </svg>
  );
};
