import React, { FC } from 'react';
import { Header } from './header';
import { CameraWrapper } from './camera-wrapper';
import { Camera } from '../../../functional/camera';
import { BlockWrapper } from '../../common/block-wrapper';
import styled from "@emotion/styled";

const Wrapper = styled(BlockWrapper)`
  @media (max-width: 767px) {
    height: calc(100vh - 170px);
    max-height: calc(100vh - 170px);
    overflow: hidden;
    position: sticky;
    top: -290px;
    z-index: 99;
  }
  
  @media (min-width: 768px) {
    position: sticky;
    top: 0;
    z-index: 2;
  }
  
  @media (min-width: 1076px) and (max-width: 1214px) {
    height: calc(100vh - 90px);
    max-height: calc(100vh - 90px);
  }

  @media (min-width: 1215px) {
    position: unset;
  }
`;

export const MainBlock: FC = () => {
  return (
    <Wrapper>
      <Header />
      <CameraWrapper>
        <Camera />
      </CameraWrapper>
    </Wrapper>
  );
};
