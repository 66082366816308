import { FaceDetectorCallbacks } from '../types/index-core';
import { useCallback, useState } from 'react';

export function useIndexes(props: {
  videoElementRef: HTMLVideoElement | null | undefined;
  callbacks: FaceDetectorCallbacks;
  canvas?: HTMLCanvasElement | null | undefined;
  afterDestroy?: () => void;
  afterInit?: () => void;
}): [() => Promise<void>, () => Promise<void>, boolean] {
  const [isInit, setInit] = useState(false);

  const initDetector = useCallback(async () => {
    if (props.videoElementRef && props.canvas) {
      await window.createFaceDetector(
        props.videoElementRef,
        props.callbacks,
        props.canvas
      );

      props.canvas.style.width = '100%';
      props.canvas.style.height = '100%';

      setInit(true);

      if (props.afterInit) {
        props.afterInit();
      }
    }
  }, [props]);

  const destroyDetector = useCallback(async () => {
    await window.destroyFaceDetector();
    setInit(false);

    if (props.canvas) {
      props.canvas.style.width = '0';
      props.canvas.style.height = '0';
    }

    if (props.afterDestroy) {
      props.afterDestroy();
    }
  }, [props]);

  return [initDetector, destroyDetector, isInit];
}
