import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { TChartDataItem } from '../utils/functions/charts';
import { IndexKey } from '../types/index-core';

type TIndexesMedium = Record<IndexKey, number>;

type TIndexesContext = {
  indexes: TChartDataItem[];
  handleAddIndex: (index: TChartDataItem[]) => void;
  average: Partial<TIndexesMedium>;
};

const IndexesContext = createContext<TIndexesContext>({
  indexes: [],
  handleAddIndex: () => {},
  average: {},
});

export const useIndexesContext = () => useContext(IndexesContext);

export const IndexesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [indexes, setIndexes] = useState<TChartDataItem[]>([]);
  const [average, setAverage] = useState<Partial<TIndexesMedium>>({});

  const handleAddIndex = useCallback((index: TChartDataItem[]) => {
    setIndexes((prev) => {
      const result = [...prev, ...index];

      if (result.length > 120) {
        result.splice(0, result.length - 120);
      }

      let newAvgObj: Partial<TIndexesMedium> = {};

      for (let i = 0; i < 5; i++) {
        const resObj = result[result.length - i - 1];

        if (resObj && resObj.key && resObj.x) {
          newAvgObj = {
            ...newAvgObj,
            [resObj.key]: resObj.x,
          };
        }
      }

      setAverage(newAvgObj);

      return result;
    });
  }, []);

  return (
    <IndexesContext.Provider
      value={{
        indexes,
        handleAddIndex,
        average,
      }}
    >
      {children}
    </IndexesContext.Provider>
  );
};
