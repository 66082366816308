import React, { FC, PropsWithChildren } from 'react';
import { ThemeProvider } from '@emotion/react';
import { darkTheme } from './index';
import { ConfigProvider, App } from 'antd';

import locale from 'antd/locale/ru_RU';

export const ThemeService: FC<PropsWithChildren> = ({ children }) => {
  return (
    <App>
      <ThemeProvider
        theme={{
          COLORS: darkTheme,
        }}
      >
        <ConfigProvider
          locale={locale}
          theme={{
            token: {
              colorPrimary: darkTheme.PRIMARY,
              colorSuccess: darkTheme.SUCCESS,
              colorWarning: darkTheme.WARNING,
              colorError: darkTheme.ERROR,
              colorInfo: darkTheme.SECONDARY,
              colorTextBase: darkTheme.SECONDARY,
              colorBgBase: darkTheme.WHITE._100,
              borderRadius: 4,
            },
          }}
        >
          {children}
        </ConfigProvider>
      </ThemeProvider>
    </App>
  );
};
