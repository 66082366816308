import styled from '@emotion/styled';

export const BlockWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 28px;

  @media (max-width: 1075px) {
    gap: 20px;
  }
`;
