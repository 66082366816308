export type IndexKey =
  | 'attention'
  | 'amazement'
  | 'happiness'
  | 'tiredness'
  | 'involvement';

export const INDEXES_KEYS: IndexKey[] = [
  'attention',
  'amazement',
  'happiness',
  'tiredness',
  'involvement',
];

export type IIndex = {
  presence: boolean;

  statistic: {
    headAngleOX: number;
    headAngleOY: number;
    eyesClosedFrames: number;
    blinkTime: number;
    blinks: number;
    eyebrowsHeight: SymmetricalValue;
    yawns: number;
    smile: number;
    attentionLostTimes: number;
    attentionLostMillieseconds: number;
    everySecondAttention: boolean;
  };
} & BaseIndex;

export type IExportIndex = {
  amazement: number;
  attention: number;
  gazesDistractions: number;
  happiness: number;
  involvement: number;
  presence: string;
  tiredness: number;
};

export interface SymmetricalValue {
  left: number;
  right: number;
}

export type BaseIndex = { [key in IndexKey]: number };

export interface FaceDetectorCallbacks {
  onIndex: (index: IIndex) => void;
  onSecondIndex: (index: { index: IExportIndex }) => void;
  onCanvasRendered?: () => void;
}
