import * as faceapi from 'face-api.js';
import type { Point } from 'face-api.js';

export function calculateTirednessFactor(landmarks: faceapi.FaceLandmarks68) {
  const leftEyeBrow = landmarks.getLeftEyeBrow();
  const rightEyeBrow = landmarks.getRightEyeBrow();
  const leftEye = landmarks.getLeftEye();
  const rightEye = landmarks.getRightEye();

  // Calculate the average height of the eyebrows and eyes
  const leftEyeBrowHeight =
    leftEyeBrow.reduce((sum: number, point: Point) => sum + point.y, 0) /
    leftEyeBrow.length;
  const rightEyeBrowHeight =
    rightEyeBrow.reduce((sum: number, point: Point) => sum + point.y, 0) /
    rightEyeBrow.length;
  const leftEyeHeight =
    leftEye.reduce((sum: number, point: Point) => sum + point.y, 0) /
    leftEye.length;
  const rightEyeHeight =
    rightEye.reduce((sum: number, point: Point) => sum + point.y, 0) /
    rightEye.length;

  const eyebrowEyeDistanceLeft = Math.abs(leftEyeBrowHeight - leftEyeHeight);
  const eyebrowEyeDistanceRight = Math.abs(rightEyeBrowHeight - rightEyeHeight);

  // Average eyebrow to eye distance
  const averageEyebrowEyeDistance =
    (eyebrowEyeDistanceLeft + eyebrowEyeDistanceRight) / 2;

  // Calculate the eye closure distance (height of the eye)
  const leftEyeClosure = Math.abs(leftEye[1].y - leftEye[5].y);
  const rightEyeClosure = Math.abs(rightEye[1].y - rightEye[5].y);

  // Average eye closure distance
  const averageEyeClosure = (leftEyeClosure + rightEyeClosure) / 2;

  // Normalize distances to a consistent scale
  const normalizedEyebrowDistance = normalize(
    averageEyebrowEyeDistance,
    10,
    30
  ); // Example range
  const normalizedEyeClosure = normalize(averageEyeClosure, 2, 10); // Example range

  // Combine distances into a single tiredness factor
  const tirednessFactor =
    (normalizedEyebrowDistance + (10 - normalizedEyeClosure)) / 2;

  return Math.round(adjustTirednessFactor(tirednessFactor));
}

function normalize(value: number, min: number, max: number): number {
  return ((value - min) / (max - min)) * 100;
}

export function adjustTirednessFactor(currentFactor: number): number {
  if (currentFactor <= 15) {
    // Map 0-15 to 100-80
    return 100 - (currentFactor / 15) * 20;
  } else if (currentFactor >= 20) {
    // Map 20-30+ to 0-20
    return ((currentFactor - 20) / 10) * 20;
  } else {
    // Reverse map 15-20 to 80-0
    return 80 - ((currentFactor - 15) / 5) * 80;
  }
}

export async function detectFaces(video: HTMLVideoElement) {
  const detections = await faceapi
    .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
    .withFaceLandmarks()
    .withFaceExpressions();

  if (detections.length > 0) {
    const expressions = detections[0].expressions;
    const landmarks = detections[0].landmarks;
    const tirednessFactor = calculateTirednessFactor(landmarks);
  }
}
