import { TStatus } from '../../types/common';
import { TFunction } from 'i18next';

export function attentionToStatus(value: number | undefined): TStatus {
  switch (true) {
    case value === undefined:
      return 'error';
    case value && value > 75:
      return 'success';
    case value && value > 50:
      return 'warning';
    default:
      return 'error';
  }
}

export const ATTENTION_STATUS_TEXT: (
  t: TFunction<string>
) => Record<TStatus, string> = (t) => ({
  error: t('statsBlock.attentionStatus.error'),
  warning: t('statsBlock.attentionStatus.warning'),
  success: t('statsBlock.attentionStatus.success'),
});
