import React, { FC } from 'react';
import styled from '@emotion/styled';
import { toTextBoolean } from '../../utils/functions/common';

const Wrapper = styled('div')<{ status: 'true' | 'false' }>`
  position: absolute;
  z-index: 2;
  right: 25px;
  bottom: 25px;
  cursor: pointer;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.COLORS.WHITE._200};
  transition: background-color 0.3s ease-out;

  svg {
    width: 32px;
  }

  path {
    fill: ${({ theme, status }) =>
      status === 'true' ? '#8F9091' : theme.COLORS.SECONDARY};
  }

  @media (max-width: 767px) {
    right: 20px;
    bottom: 20px;
    width: 50px;
    height: 50px;

    svg {
      width: 24px;
    }
  }
`;

type TProps = {
  onClick: () => void;
  state: boolean;
};

export const CameraButton: FC<TProps> = ({ onClick, state }) => {
  return (
    <Wrapper onClick={onClick} status={toTextBoolean(state)}>
      {state ? (
        <svg
          width="32"
          height="26"
          viewBox="0 0 32 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M30.6719 22.5156C31 22.75 31.0938 23.2188 30.8125 23.5469L29.9219 24.7188C29.6406 25.0469 29.1719 25.0938 28.8438 24.8594L20.5 18.3906L2.96875 4.84375L1.28125 3.53125C0.953125 3.29688 0.90625 2.82812 1.14062 2.5L2.07812 1.32812C2.3125 1 2.78125 0.90625 3.10938 1.1875L6.76562 4H18.25C19.4688 4 20.5 5.03125 20.5 6.25V14.5938L22 15.7656V9.34375L27.1094 5.78125C28.0938 5.125 29.5 5.78125 29.5 7V19.0469C29.5 19.8438 28.8438 20.4531 28.0938 20.5L30.6719 22.5156ZM2.5 19.7969V8.26562L19.6094 21.5312C19.2344 21.8125 18.7656 22 18.25 22H4.70312C3.48438 22 2.5 21.0156 2.5 19.7969Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg viewBox="0 0 32 21" fill="none">
          <path d="M18.625 0C20.0469 0 21.25 1.20312 21.25 2.625V18.4297C21.25 19.8516 20.0469 21 18.625 21H2.82031C1.39844 21 0.25 19.8516 0.25 18.4297V2.625C0.25 1.20312 1.39844 0 2.82031 0H18.625ZM28.9609 2.07812C30.1094 1.3125 31.75 2.13281 31.75 3.5V17.5547C31.75 18.9219 30.1094 19.7422 28.9609 18.9766L23 14.8203V6.23438L28.9609 2.07812Z" />
        </svg>
      )}
    </Wrapper>
  );
};
