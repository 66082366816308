import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

export type TLocales = 'ru' | 'en';

export type TLocaleContext = {
  lang: TLocales | undefined;
  availableOptions: {
    key: TLocales;
    label: string;
  }[];
  setLocale: (locale: TLocales) => void;
};

const LS_LANG_KEY = 'index5_Page_language';

const context = createContext<TLocaleContext>({
  lang: localStorage.getItem(LS_LANG_KEY) as TLocales | undefined,
  availableOptions: [
    {
      key: 'ru',
      label: 'Русский',
    },
    {
      key: 'en',
      label: 'English',
    },
  ],
  setLocale: () => {},
});

export const useLocaleContext = () => useContext(context) as TLocaleContext;

export const LocaleContext: FC<PropsWithChildren> = ({ children }) => {
  const { i18n } = useTranslation();

  const [currentLang, setCurrentLang] = useState<TLocales | undefined>(
    localStorage.getItem(LS_LANG_KEY) as TLocales | undefined
  );

  const handleSetLocale = useCallback((locale: TLocales) => {
    localStorage.setItem(LS_LANG_KEY, locale);
    setCurrentLang(locale);
    void i18n.changeLanguage(locale);
  }, []);

  useEffect(() => {
    if (!currentLang && ['ru', 'en'].includes(i18n.language)) {
      setCurrentLang(i18n.language as TLocales);
      localStorage.setItem(LS_LANG_KEY, i18n.language);
    }
  }, []);

  return (
    <context.Provider
      value={{
        lang: currentLang,
        availableOptions: [
          {
            key: 'ru',
            label: 'Русский',
          },
          {
            key: 'en',
            label: 'English',
          },
        ],
        setLocale: handleSetLocale,
      }}
    >
      {children}
    </context.Provider>
  );
};
