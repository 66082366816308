import React, { FC, PropsWithChildren } from 'react';
import styled from '@emotion/styled';

const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.COLORS.WHITE._300};
  overflow: hidden;

  @media (max-width: 1075px) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    height: 100vh;
    max-height: 100vh;
    z-index: 1;
  }

  @media (max-width: 767px) {
    position: relative;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    background-color: ${({ theme }) => theme.COLORS.WHITE._100};
  }
`;

export const CameraWrapper: FC<PropsWithChildren> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};
