import React, { forwardRef } from 'react';
import Webcam from 'react-webcam';

type TProps = {
  active: boolean;
};

type TRef = Webcam | null;

export const CameraVideo = forwardRef<TRef, TProps>((props, ref) => {
  return (
    <Webcam
      autoPlay={false}
      audio={false}
      mirrored={true}
      ref={ref}
      screenshotFormat="image/jpeg"
      forceScreenshotSourceSize={true}
      style={{
        display: 'none',
      }}
    />
  );
});
