import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import langRu from './ru.json';
import langEn from './en.json';

export enum TLocaleKey {
  DEMO = 'demoPage',
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: false,
    resources: {
      en: {
        [TLocaleKey.DEMO]: langEn,
      },
      ru: {
        [TLocaleKey.DEMO]: langRu,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
