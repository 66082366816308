import { IExportIndex, INDEXES_KEYS, IndexKey } from '../../types/index-core';

export type TChartDataItem = {
  x: string | number | boolean;
  key: IndexKey;
};

export function indexesToChartData(indexes: IExportIndex): TChartDataItem[] {
  const indexesArray: TChartDataItem[] = [];

  (Object.keys(indexes) as (keyof IExportIndex)[]).forEach(
    (el: keyof IExportIndex) => {
      if (INDEXES_KEYS.includes(el as any)) {
        indexesArray.push({
          key: el as IndexKey,
          x: indexes[el as IndexKey],
        });
      }
    }
  );

  return indexesArray;
}
