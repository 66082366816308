import React, { FC } from 'react';
import styled from '@emotion/styled';

import { LangSwitcher } from '../../common/lang-switcher';

const Wrapper = styled('div')`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const Header: FC = () => {
  return (
    <Wrapper>
      <LangSwitcher />
    </Wrapper>
  );
};
