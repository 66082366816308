import React, { Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { RootRoutes } from './utils/routes/root-routes';
import { createRoot } from 'react-dom/client';
import { ThemeService } from './utils/theme/theme-service';

import './index-core/bundle';
import 'antd/dist/reset.css';
import './utils/theme/style.scss';
import { ContextProviders } from './context';
import { I18nextProvider } from 'react-i18next';
import i18next from './locale';
import { LocaleContext } from './context/locale-context';

const container = document.getElementById('root');

function App() {
  return (
    <Fragment>
      <I18nextProvider i18n={i18next}>
        <LocaleContext>
          <BrowserRouter>
            <ThemeService>
              <ContextProviders>
                <RootRoutes />
              </ContextProviders>
            </ThemeService>
          </BrowserRouter>
        </LocaleContext>
      </I18nextProvider>
    </Fragment>
  );
}

if (container) {
  const root = createRoot(container);

  root.render(<App />);
} else {
  throw new Error("Корневой элемент c id='root' не найден");
}
