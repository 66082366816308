import { useCallback, useEffect } from 'react';
import * as faceapi from 'face-api.js';
import {
  calculateTirednessFactor,
} from '../utils/functions/tiredness';

export function useTirednessDetector() {
  const detectFaces = useCallback(async (video: HTMLVideoElement) => {
    const detections = await faceapi
      .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
      .withFaceLandmarks()
      .withFaceExpressions();

    if (detections.length > 0) {
      const expressions = detections[0].expressions;
      const landmarks = detections[0].landmarks;
      const tirednessFactor = calculateTirednessFactor(landmarks);

      return {
        tirednessFactor,
        expressions
      };
    }

    return {
      tirednessFactor: 0,
      expressions: []
    };
  }, []);

  useEffect(() => {
    const modelBaseUrl =
      'https://cdn.jsdelivr.net/npm/@vladmandic/face-api/model/';

    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri(modelBaseUrl),
      faceapi.nets.faceLandmark68Net.loadFromUri(modelBaseUrl),
      faceapi.nets.faceRecognitionNet.loadFromUri(modelBaseUrl),
      faceapi.nets.faceExpressionNet.loadFromUri(modelBaseUrl),
    ]);
  }, []);

  return { detectFaces };
}
