import React, { FC, useEffect, useRef, useState } from 'react';
import { TChartColor } from '../../../../types/common';
import styled from '@emotion/styled';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';
import { chartToColor } from '../../../../utils/functions/colors';
import { TChartDataItem } from '../../../../utils/functions/charts';

type TProps = {
  chartData: TChartDataItem[];
  color: TChartColor;
  keyS: string;
};

const Wrapper = styled('div')`
  display: block;
  height: 100%;
`;

export const ChartContent: FC<TProps> = ({ chartData, color, keyS }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [contentWidth, setContentWidth] = useState(0);

  useEffect(() => {
    const listener = () => {
      setContentWidth(0);

      setTimeout(() => {
        if (wrapperRef.current) {
          setContentWidth(wrapperRef.current.clientWidth);
        }
      }, 0);
    };

    listener();

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <ResponsiveContainer height={'100%'} width={contentWidth}>
        <AreaChart
          data={chartData}
          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id={`colorUv${keyS}`} x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={chartToColor(color)}
                stopOpacity={0.2}
              />
              <stop
                offset="95%"
                stopColor={chartToColor(color)}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="x"
            stroke={chartToColor(color)}
            fillOpacity={1}
            fill={`url(#colorUv${keyS})`}
            isAnimationActive={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Wrapper>
  );
};
